<template>
  <div>
    <div v-if="callSummaryLoading">
      <progress-indicator></progress-indicator>
    </div>

    <div v-if="!callSummaryLoading && summary">
      <div v-if="!printView">
        <div class="container-fluid">
          <div :class="summaryHeaderClass">
            <!-- Title -->
            <div v-if="isOpenFromCallViewSummary"
                 class="d-flex align-items-center justify-content-between"
            >
              <h3 id="conversation-summary-title-202101041318"
                  class="title"
              >
                {{ staticText.callWrapUpLabel }}
              </h3>
              <div v-if="canModifyCallsRealTag" class="mr-2 d-md-none">
                <bao-checkbox
                  :value="isCallTaggedReal"
                  :switchStyle="false"
                  :label="staticText.realCallLabel"
                  @input="updateCallRealTag($event)"
                />
              </div>
            </div>

            <!-- call summary action buttons -->
            <div id="summary-action-buttons-21911112"
                 class="d-flex"
            >
              <div v-if="canModifyCallsRealTag" class="mr-2 d-md-flex d-none">
                <bao-checkbox
                  :value="isCallTaggedReal"
                  :switchStyle="false"
                  :label="staticText.realCallLabel"
                  @input="updateCallRealTag($event)"
                />
              </div>
              <call-summary-controllers
                :id="'summary-controllers-21971340'"
                :summary="summary"
                :call-id="callId"
                :action-button-config="actionButtonConfig"
                class="mr-3"
                @deleteCall="deleteCall()"
              ></call-summary-controllers>

              <!-- save to CRM button -->
              <save-to-crm-button
                v-if="canUseSaveToCrmFeature"
                :summary="summary"
                :shouldSaveNotesToCrm="shouldSaveNotesToCrm"
                :shouldSaveAISummaryToCrm="shouldSaveAISummaryToCrm"
                :textSummary="textSummary"
                :callNotes="callNotes"
                @call-saved-to-crm="isCallSavedToCrm = true"
              />
              <router-link
                v-else
                id="start-new-conversation-btn-202101041318"
                to="/call"
                class="btn px-md-3 w-100 btn-primary d-block d-lg-none"
                :replace="isApplicationInIFrame"
              >
                {{ staticText.startNewConversationBtn }}
              </router-link>
            </div>
          </div>

          <div class="row">
            <!--left column  -->
            <div class="col-md-12 col-lg-4 px-0 px-md-3">
              <div class="summary-components">
                <div class="col-12 px-0">
                  <!-- Playbook -->
                  <div
                    v-if="!!summary.talkscript"
                    class="mb-3"
                  >
                    <p class="summary-value font-700">
                      {{ summary.talkscript.name }}
                    </p>
                  </div>

                  <div class="row">
                    <!-- Counterparts -->
                    <div
                      id="sm-counterparts-21911641"
                      class="mb-3 col-6 col-lg-12"
                    >
                      <div class="d-flex align-items-center mb-2">
                        <p class="summary-title mb-0">{{ staticText.counterpartLabel }}</p>
                        <b-btn
                          v-if="counterpartsAvailable"
                          @click="openCounterpartSelectionModal"
                          class="bg-transparent counterpart-edit-btn"
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.95 2.657l3.254 3.255-8.239 8.239-3.253-3.255L9.95 2.657zm5.724-.785L14.222.421a1.44 1.44 0 00-2.034 0l-1.39 1.39 3.254 3.255 1.622-1.622a1.11 1.11 0 000-1.572zM.009 15.471a.37.37 0 00.448.44l3.627-.88-3.253-3.254-.822 3.694z" fill="#AAABBA"/></svg>
                        </b-btn>
                      </div>

                      <div
                        v-if="!counterpartsAvailable"
                        class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center"
                      >
                        <div class="no-counter-part-selected-text">{{ staticText.noCounterpartsSelected }}</div>

                        <div
                          v-if="counterpartAutoSearchLoading"
                          class="w-fit-content mt-1 mt-xl-0"
                        >
                          <progress-indicator
                            :small="true"
                            :show-loading-label="false"
                          ></progress-indicator>
                        </div>
                        <button
                          v-else
                          class="select-counterpart-button mt-1 mt-xl-0"
                          @click="openCounterpartSelectionModal"
                        >
                          {{ staticText.selectCounterpartsLabel }}
                        </button>
                      </div>

                      <div
                        v-else
                        class="w-100"
                      >
                        <counterpart-display-cell
                          v-for="(counterpart, index) in summary.counterparts"
                          :key="index"
                          :id="'counterpart-display-202103221659-'+index"
                          :name="counterpart.name"
                          :type="counterpart.type"
                          :direct_link="counterpart.direct_link"
                          :crm_service="counterpart.crm_service"
                          :isOpenFromCallViewSummary="isOpenFromCallViewSummary"
                          class="single-counterpart"
                        >
                          <template slot="end">
                            <div class="d-flex">
                              <error-modal
                                :id="'counterpart-error-btn-202103221658-' + index"
                                v-if="counterpart.errors"
                                error-header-text=""
                                :title=staticText.errorSavingCRM
                                :errors="counterpart.errors"
                              ></error-modal>
                            </div>
                          </template>
                        </counterpart-display-cell>
                      </div>

                      <!-- counterpart selection modal  -->
                      <counterpart-selection-modal
                        ref="counterpartSelectionModal"
                        :counterparts="summary.counterparts"
                        @set-counterparts="setCounterParts"
                        @counterparts-modal-closed="saveCallCounterparts"
                      />
                    </div>

                    <!-- Call Duration -->
                    <div
                      v-if="summary.duration && summary.duration > 0"
                      class="mb-3 col-6 col-lg-12"
                    >
                      <p class="summary-title">{{ staticText.callDurationLabel }}</p>
                      <p class="summary-value">
                        {{ convertMillisecondsToHHMMSS(summary.duration) }}
                      </p>
                    </div>
                  </div>

                  <div
                    :class="['other-call-details', {'extended' : showOtherCallDetails}]"
                  >
                    <!-- User -->
                    <div
                      v-if="!!summary.created_by"
                      class="mb-3"
                    >
                      <p class="summary-title">{{ staticText.userLabel }}</p>
                      <p class="summary-value">
                        {{ summary.created_by.full_name }}
                      </p>
                    </div>

                    <!-- Call Result -->
                    <div
                      v-if="canUseCallResult && summary.result"
                      :id="'call-result-'+summary.id"
                      class="mb-3"
                    >
                      <p class="summary-title">{{ staticText.conversationResultLabel }}</p>
                      <p class="summary-value">
                        {{ summary.result }}
                      </p>
                    </div>

                    <!-- Call Transcript and Text-Match data -->
                    <div v-if="!!callTranscriptData || transcriptDataLoading"
                         class=""
                    >
                      <text-match-progress-indicator
                        v-if="isOpenFromCallViewSummary && transcriptDataLoading"
                      />
                      <div v-else>
                        <real-time-assistant-arc-pie
                          v-if="isValidNumber(callTranscriptData.talking_share)"
                          :label="staticText.talkingShareLabel"
                          label-placement="right"
                          :value="callTranscriptData.talking_share"
                          :size="40"
                        ></real-time-assistant-arc-pie>
                        <real-time-assistant-arc-pie
                          v-if="isValidNumber(callTranscriptData.talking_speed)"
                          :label="staticText.talkingSpeedLabel"
                          label-placement="right"
                          :value="callTranscriptData.talking_speed"
                          :inner-text="'' + callTranscriptData.talking_speed.toFixed(0)"
                          :total="220"
                          :size="40"
                        ></real-time-assistant-arc-pie>
                        <real-time-assistant-arc-pie
                          v-if="showTextMatch && isValidNumber(callTranscriptData.text_similarity)"
                          :label="staticText.textSimilarityLabel"
                          label-placement="right"
                          :value="callTranscriptData.text_similarity"
                          :size="40"
                        ></real-time-assistant-arc-pie>
                      </div>
                    </div>

                    <!-- bao Value -->
                    <div v-if="baoValueAvailable"
                         class="form-group mt-2"
                    >
                      <bao-value-popover :bao-values="summary.bao_values"></bao-value-popover>
                    </div>
                  </div>
                  <div
                    role="button"
                    :class="['toggle-button d-lg-none', { 'rotate' : showOtherCallDetails }]"
                    @click="showOtherCallDetails = !showOtherCallDetails"
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.002 7.851a.847.847 0 01.456-.753.86.86 0 01.946.099l4.584 3.808 4.593-3.672a.857.857 0 011.206.127.848.848 0 01-.128 1.241l-5.132 4.106a.858.858 0 01-1.086 0l-5.132-4.25a.85.85 0 01-.307-.706z" fill="#2A2D52"/></svg>
                  </div>
                </div>
              </div>
              <!--  new conversation button / displayed only in lg/xl screen size          -->
              <div v-if="isOpenFromCallViewSummary"
                   class="d-flex flex-column"
              >
                <router-link id="start-new-conversation-btn-202101041318"
                             to="/call"
                             class="btn btn-light mt-2 mb-2 p-3 justify-content-center align-items-center d-none d-lg-flex"
                             :replace="isApplicationInIFrame"
                >
                  {{ staticText.startNewConversationBtn }}
                  <i class="fas fa-chevron-right pl-2 pb-1"></i>
                </router-link>

                <router-link id="call-feedback-btn-202101041318"
                             v-if="canUseBaoAudio"
                             target="_blank"
                             :to="`/calls/${callId}`"
                             class="btn btn-light p-3 justify-content-center align-items-center d-none d-lg-flex"
                >
                  {{ staticText.showCallFeedbackLabel }}
                  <i class="fas fa-chevron-right pl-2 pb-1"></i>
                </router-link>

                <!--Write E-Mail button / visible if feature is supported by integrated CRM  -->
                <b-btn id="write-email-btn-202101041318"
                       v-if="canSendEmailThroughCRM"
                       class="btn btn-light my-2 p-3 justify-content-center align-items-center"
                       @click="openEmailEditor"
                >
                  {{ staticText.writeEmailLabel }}
                  <i class="fas fa-chevron-right pl-2 pb-1 d-none d-lg-inline-block"></i>
                </b-btn>

              </div>
            </div>

            <!--right column  -->
            <div class="col-md-12 col-lg-8 px-0 px-md-3">
              <!-- Call Flow Summary table section -->
              <div id="call-flow-summary-202012241532">
                <div class="call-notes my-3 mt-lg-0">

                  <div class="call-notes__header">
                    <div class="d-flex align-items-center mr-2 mr-md-3">
                      <h1 class="mb-0">{{ staticText.callNotesLabel }}</h1>
                      <bao-copy-button
                        ref="baoCopyButton"
                        :shouldInitiateCopyProcess="true"
                        @initiate-copy-process="copyTextSummary"
                      />
                    </div>

                    <div>
                      <div
                        v-if="!isCallSavedToCrm"
                        class="d-flex"
                      >
                        <div
                          v-if="showSaveToCrmToggle"
                        >
                          <bao-toggle-button
                            id="save-notes-to-crm-toggle"
                            :isActive="shouldSaveNotesToCrm"
                            :label="staticText.saveToCRMLabel"
                            :isDisabled="isCallSavedToCrm"
                            :button-class="['minBtn font-14 mr-2 border-r-black-06']"
                            @toggle="toggleSaveNotesToCrm(!shouldSaveNotesToCrm)"
                          ></bao-toggle-button>
                          <bao-toggle-button
                            id="save-short-summary-to-crm-toggle"
                            :isActive="shortSummaryEnabled"
                            :isDisabled="isCallSavedToCrm"
                            :label="staticText.saveAnswersAndNotesLabel"
                            :button-class="['minBtn font-14']"
                            @toggle="toggleSaveAnswersAndNotes(!shortSummaryEnabled)"
                          ></bao-toggle-button>
                        </div>
                      </div>
                      <p
                        v-else
                        class="save-text"
                      >
                        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.037 7.397C1.108 7.592.4 8.602.4 9.817v5.728C.4 16.901 1.28 18 2.364 18h9.164c1.085 0 1.964-1.099 1.964-2.455V9.818c0-1.216-.708-2.226-1.637-2.42V4.908a4.91 4.91 0 10-9.818 0v2.488zm1.636-.034h6.546V4.91a3.273 3.273 0 00-6.546 0v2.454z" fill="#AAABBA"/></svg>
                        {{ staticText.cannotSaveLabel }}
                      </p>
                    </div>
                    <!-- Auto populate CRM data from Transcript -->
                    <!-- Only for Internal test for now -->
                    <TranscriptCRMFieldsPreSelection v-if="!hideCrmFieldAutoDetectFeature"/>

                  </div>

                  <call-flow-items
                    v-if="!callDataLoading"
                    :isCallSavedToCrm="isCallSavedToCrm"
                  />

                  <speech-to-text-notes
                    :value="callNotes"
                    :notes-label="staticText.additionalNotesLabel"
                    :disabled="isCallSavedToCrm"
                    @save-notes="saveCallNotes"
                    @note-changed="setCallNotes"
                  />
                </div>
              </div>
              <div v-if="canUseBaoAudio" class="mb-3">
                <bao-video
                  v-if="isVideoAvailable"
                  :isOpenFromCallViewSummary="isOpenFromCallViewSummary"
                  :showVideoPlayer="false"
                  :callId="callId"
                  :showSaveToCrmToggle="showSaveToCrmToggle"
                  :isCallSavedToCrm="isCallSavedToCrm"
                  :shouldSaveAISummaryToCrm="shouldSaveAISummaryToCrm"
                  @toggleSaveAISummaryToCrm="shouldSaveAISummaryToCrm = !shouldSaveAISummaryToCrm"
                  class="mb-3"
                ></bao-video>
                <BaoAudio
                  v-else
                  :call-id="callId"
                  :show-audio-player="false"
                  :is-open-from-call-view-summary="isOpenFromCallViewSummary"
                  :shouldSaveAISummaryToCrm="shouldSaveAISummaryToCrm"
                  :showSaveToCrmToggle="showSaveToCrmToggle"
                  :isCallSavedToCrm="isCallSavedToCrm"
                  @toggleSaveAISummaryToCrm="shouldSaveAISummaryToCrm = !shouldSaveAISummaryToCrm"
                >
                </BaoAudio>
              </div>

              <!-- Email Editor -->
              <div id="email-editor-202210241555"
                   v-if="showEmailEditor"
              >
                <email-editor
                  :pre-selected-recipients="summary.counterparts"
                  @closed="showEmailEditor=false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="printView">
        <call-summary-print-content
          :call-transcript-data="callTranscriptData"
          :summary="summary"
          :text-summary="textSummary"
        ></call-summary-print-content>
      </div>
    </div>

  </div>
</template>

<script>
import { getPlainTextToCopy, strip } from "./index.js"
import { baoDelayService } from "../call"
import axios from "axios"
import CounterpartDisplayCell from "../call/CounterpartDisplayCell"
import ErrorModal from "../base/ErrorModal"
import { mapActions, mapGetters, mapMutations } from "vuex"
import ProgressIndicator from "../base/ProgressIndicator"
import BaoValuePopover from "../baovalue/BaoValuePopover"
import he from "he"
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"
import CallSummaryControllers from "@/apps/call_history/CallSummaryComponents/CallSummaryControllers"
import CallSummaryPrintContent from "@/apps/call_history/CallSummaryComponents/CallSummaryPrintContent"
import EmailEditor from "@/apps/call_history/CallSummaryComponents/EmailEditor"
import SaveToCrmButton from "@/apps/call_history/CallSummaryComponents/SaveToCrmButton"
import TextMatchProgressIndicator from "./CallSummaryComponents/TextMatchProgressIndicator"
import BaoAudio from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoAudio.vue"
import BaoVideo from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoVideo.vue"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import SpeechToTextNotes from "./CallFeedbackComponents/SpeechToTextNotes.vue"
import CallFlowItems from "./CallSummaryComponents/CallFlowItems.vue"
import BaoCopyButton from "@/apps/base/BaoCopyButton"
import CounterpartSelectionModal from "@/apps/call/CallViewCounterpartComponents/CounterpartSelectionModal"
import {
  fetchMeetingParticipantsApi
} from "@/apps/call/api"
import TranscriptCRMFieldsPreSelection from "@/apps/call_history/CallSummaryComponents/TranscriptCRMFieldsPreSelection"
import BaoCheckbox from "@/apps/base/BaoCheckbox.vue"
import { updateCallsRealTagApi } from "@/apps/call_history/api"
import { CALL_TYPE_REAL } from "@/apps/base/constants"

export default {
  name: "CallSummary",
  components: {
    BaoAudio,
    BaoValuePopover,
    ProgressIndicator,
    ErrorModal,
    CounterpartDisplayCell,
    RealTimeAssistantArcPie,
    CallSummaryControllers,
    CallSummaryPrintContent,
    EmailEditor,
    SaveToCrmButton,
    TextMatchProgressIndicator,
    BaoToggleButton,
    SpeechToTextNotes,
    BaoVideo,
    CallFlowItems,
    BaoCopyButton,
    BaoCheckbox,
    CounterpartSelectionModal,
    TranscriptCRMFieldsPreSelection
  },
  props: {
    isOpenFromCallViewSummary: {
      type: Boolean,
      default: false
    },
    callId: {
      type: [Number, String]
    },
    printView: {
      type: Boolean,
      default: false
    },
    wasLiveConversation: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      axios,
      showPersonalityForm: false,
      selectedCp: null,
      selectedCpIndex: null,
      staticTextDefault: {
        noCounterpartsSelected: "No Counterpart selected",
        conversationResultLabel: "Conversation Result",
        userLabel: "User",
        playbookLabel: "Playbook",
        counterpartLabel: "Counterpart",
        yourNotesLabel: "Your Notes",
        callNotesLabel: "Call notes",
        notesLabel: "Notes",
        saveToCRMLabel: "Save to CRM",
        errorSavingCRM: "Error while saving to CRM",
        communicationProfile: "Communication Profile",
        callDurationLabel: "Duration",
        delete: "Delete",
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Tempo (WPM)",
        textSimilarityLabel: "Text-Match",
        startNewConversationBtn: "New Conversation",
        callWrapUpLabel: "Call Wrap-Up",
        showCallFeedbackLabel: "Show Call Details",
        saveAnswersAndNotesLabel: "Save answers & notes only",
        writeEmailLabel: "Write E-Mail",
        additionalNotesLabel: "Additional Notes",
        cannotSaveLabel: "You cannot make changes to the saved file",
        selectCounterpartsLabel: "Select now",
        realCallLabel: "Real Call"
      },
      actionButtonConfig: [],
      shortSummaryEnabled: false,
      showEmailEditor: false,
      shouldSaveNotesToCrm: true,
      shouldSaveAISummaryToCrm: true,
      isCallSavedToCrm: false,
      showOtherCallDetails: false,
      callNotesChanged: false,
      callDataLoading: false,
      counterpartAutoSearchLoading: false
    }
  },
  computed: {
    ...mapGetters({
      canUseBaoValue: "auth/canUseBaoValue",
      canUseCallResult: "auth/canUseCallResult",
      canUseCRM: "auth/canUseCRM",
      canUseSpeechRecognition: "auth/canUseSpeechRecognition",
      canUseBaoAudio: "auth/canUseBaoAudio",
      showTextMatch: "auth/showTextMatch",
      canSendEmailThroughCRM: "auth/canSendEmailThroughCRM",
      callTranscriptData: "callAudioStore/getCallTranscriptData",
      transcriptDataLoading: "callAudioStore/getTranscriptDataLoading",
      user: "auth/user",
      canUseSaveToCrmFeature: "auth/canUseSaveToCrmFeature",
      summary: "callSummaryStore/getCallSummary",
      callSummaryLoading: "callSummaryStore/getSummaryLoading",
      callNotes: "callSummaryStore/getCallNotes",
      canUseVideoIntegration: "auth/canUseVideoIntegration",
      isVideoAvailable: "callVideoStore/isVideoAvailable",
      getMeetingSummary: "callVideoStore/getMeetingSummary",
      canModifyCallsRealTag: "auth/canModifyCallsRealTag"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    hideCrmFieldAutoDetectFeature () {
      return process.env.VUE_APP_DOMAIN === "app.bao.solutions"
    },
    counterpartsAvailable () {
      return !!(this.summary.counterparts && this.summary.counterparts.length)
    },
    baoValueAvailable () {
      return this.canUseBaoValue && this.summary.bao_values && this.summary.bao_values.length > 0
    },
    showSaveToCrmToggle () {
      return this.canUseCRM && this.counterpartsAvailable
    },
    userLanguage () {
      return this.user.language === "de" ? "de-DE" : "en-US"
    },
    textSummary () {
      return this.shortSummaryEnabled ? this.getNotesToCopy(this.summary) : this.getPlainTextToCopy(this.summary)
    },
    counterparts () {
      return this.summary.counterparts
    },
    isCallTriggeredFromMeet () {
      return !!this.$route.query.meetingUrl
    },
    summaryHeaderClass () {
      let headerClasses = "row justify-content-md-between align-items-md-center mb-3 px-md-3 flex-column"
      headerClasses += this.isOpenFromCallViewSummary ? " flex-md-row" : " flex-md-row-reverse"
      return headerClasses
    },
    isCallTaggedReal () {
      return this.summary && this.summary.tags && this.summary.tags.some(tag => tag.name.includes(CALL_TYPE_REAL))
    }

  },
  watch: {
    callNotes () {
      // This watcher is just to track if the notes actually changed, before saving it @blur
      // of the textarea
      this.callNotesChanged = true
    }
  },
  async mounted () {
    /***
     * check for video integration permission
     ***/
    if (this.canUseVideoIntegration) {
      this.resetCallVideoStore()
      this.checkForMeetingData()
    }
    this.setUp()
  },
  methods: {
    ...baoDelayService.methods,
    ...mapActions({
      deleteSelectedCalls: "calls/deleteSelectedCalls",
      fetchCallTranscriptData: "callAudioStore/fetchCallTranscriptData",
      fetchCallDetails: "callSummaryStore/fetchCallDetails",
      fetchCallSummary: "callSummaryStore/fetchCallSummary",
      saveNotes: "callSummaryStore/saveCallNotes",
      fetchCallMeetingData: "callVideoStore/fetchCallMeetingData",
      resetCallVideoStore: "callVideoStore/resetCallVideoStore",
      saveCounterparts: "callSummaryStore/saveCounterparts"
    }),
    ...mapMutations({
      setCallNotes: "callSummaryStore/setCallNotes",
      setCounterParts: "callSummaryStore/setCounterParts"
    }),

    async updateCallRealTag (value) {
      const payload = {
        call_ids: [this.callId],
        mark_calls_real: value
      }
      await updateCallsRealTagApi(payload)
      // fetch the refreshed call
      await this.fetchCallDetails({ callId: this.callId })
    },
    openCounterpartSelectionModal () {
      this.$refs.counterpartSelectionModal.openModal()
    },
    async checkForMeetingData () {
      await this.fetchCallMeetingData({ callId: this.callId })
    },
    handleWindowResize () {
      // If open on a large screen, show all the call details
      if (window.innerWidth > 991) this.showOtherCallDetails = true
    },
    toggleSaveNotesToCrm (value) {
      this.shouldSaveNotesToCrm = value
      if (!value) this.toggleSaveAnswersAndNotes(false)
    },
    toggleSaveAnswersAndNotes (value) {
      this.shortSummaryEnabled = value
      localStorage.setItem("saveAnswersToCrmValue", value)
      if (value) this.toggleSaveNotesToCrm(true)
    },
    openEmailEditor () {
      this.showEmailEditor = true
      this.$nextTick().then(() => {
        const element = document.getElementById("email-editor-202210241555")
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      })
    },
    setUp () {
      this.callDataLoading = true
      this.loadCallData().then(() => {
        if (this.canUseVideoIntegration) this.handleCallTriggeredFromMeeting()
        this.callDataLoading = false
        this.getActionButtons()
        if (this.isOpenFromCallViewSummary && this.wasLiveConversation) this.$emit("call-tags-retrieved", this.summary.tags)
      })
      if (this.canUseSpeechRecognition) this.fetchCallTranscriptData({ callId: this.callId })

      this.updateSaveAnswersToCrmValue()
      window.addEventListener("resize", this.handleWindowResize)
      this.handleWindowResize()
    },
    updateSaveAnswersToCrmValue () {
      const saveAnswersToCrmValue = localStorage.getItem("saveAnswersToCrmValue")
      if (!saveAnswersToCrmValue) localStorage.setItem("saveAnswersToCrmValue", false)
      else this.shortSummaryEnabled = JSON.parse(saveAnswersToCrmValue)
    },
    hasBaoValue (data) {
      return data.item && data.item.call_item && data.item.call_item.bao_value
    },
    saveCallNotes () {
      if (!this.callNotesChanged) return
      this.saveNotes({ callId: this.callId })
      this.callNotesChanged = false
    },
    saveCallCounterparts () {
      this.saveCounterparts({ callId: this.callId })
    },
    async loadCallData () {
      const fetchCallSummary = this.fetchCallSummary({ callId: this.callId })
      const fetchCallDetails = this.fetchCallDetails({ callId: this.callId })
      await Promise.all([fetchCallSummary, fetchCallDetails])
    },
    getActionButtons () {
      // Note: the transformation of action button urls can only occur once the summary is completely loaded
      const that = this
      return new Promise((resolve, reject) => {
        that.axios.get("/api/actionbuttons").then(response => {
          that.actionButtonConfig = this.setupActionButton(response.data.results)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getValue (key) {
      const path = key.split(".")
      let current = this
      while (path.length) {
        try {
          current = current[path.shift()]
        } catch (e) {
          console.error(e)
          return null
        }
      }
      return current
    },
    transformURL (url) {
      // we split the URL at the first "&" so we can extract the single params and the calculate the values accordingly
      const splitted = url.split("&")
      let urlBeginning = splitted[0]
      splitted.splice(0, 1)
      if (splitted.length > 0) {
        const str = "{\"" + splitted.join("\",\"").replace(/=/g, "\":\"") + "\"}"
        const params = JSON.parse(str, function (key, value) {
          return key === "" ? value : decodeURIComponent(value)
        })
        for (const key in params) {
          const keySplit = params[key].split(",")
          for (const ks in keySplit) {
            const value = this.getValue(keySplit[ks])
            if (value) urlBeginning += "&" + key + "=" + encodeURIComponent(this.strip(he.decode(value)))
          }
        }
      }
      return urlBeginning
    },
    setupActionButton (config) {
      const result = []
      for (const i in config) {
        const ab = config[i]
        if (ab.url) {
          ab.url = this.transformURL(ab.url)
        }
        result.push(ab)
      }
      return result
    },
    strip (html) {
      return strip(html)
    },
    getPlainTextToCopy (item) {
      return getPlainTextToCopy(item, true)
    },
    getNotesToCopy (item) {
      return getPlainTextToCopy(item, false)
    },
    async copyTextSummary () {
      // When a user clicks the copy button, I first update the call Summary
      // information incase the user has edited the call, before values are copied
      await this.fetchCallSummary({ callId: this.callId, showLoader: false })
      const textToCopy = this.callNotes ? `${this.textSummary} \n${this.staticText.additionalNotesLabel}: ${this.callNotes}\n` : this.textSummary
      this.$refs.baoCopyButton.copyText(textToCopy)
    },
    deleteCall () {
      return new Promise((resolve, reject) => {
        this.deleteSelectedCalls([this.summary]).then(() => {
          const redirectPath = this.isOpenFromCallViewSummary ? "call" : "calls"
          this.pushOrReplaceRoute(`/${redirectPath}`)
          this.$emit("deleted")
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async fetchMeetingParticipants () {
      const params = { call_id: this.callId }
      try {
        const { data } = await fetchMeetingParticipantsApi(params)
        const meetingParticipants = data
        return meetingParticipants
      } catch (error) {
        this.counterpartAutoSearchLoading = false
        console.error(error)
      }
    },
    handleCallTriggeredFromMeeting () {
      // If the call was triggered from the meeting url, the url would
      // be passed to this route. Then we auto search for counterparts based
      // on the meeting participants
      if (this.isCallTriggeredFromMeet) this.findCounterpartsFromParticipants()
    },
    async findCounterpartsFromParticipants () {
      if (this.counterpartsAvailable) return

      this.counterpartAutoSearchLoading = true

      const meetingParticipants = await this.fetchMeetingParticipants()
      const searchCounterpartPromises = []
      meetingParticipants.forEach((participant) => {
        // Here we assume that the host is the bao user, so all other participants asides
        // the host are potential counterparts
        if (!participant.is_host) searchCounterpartPromises.push(this.searchForCounterpart(participant.name))
      })
      await Promise.all(searchCounterpartPromises).then((records) => {
        let counterparts = []
        records.forEach((record) => {
          counterparts = counterparts.concat(record)
        })

        if (counterparts.length) this.setCounterParts(counterparts)

        this.counterpartAutoSearchLoading = false
        this.openCounterpartSelectionModal()
      })
    },
    async searchForCounterpart (query) {
      try {
        const { data } = await this.axios.get(
          "/api/counterpartsnew/search?q=" + query
        )
        return data.records
      } catch (error) {
        console.error(error)
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.handleWindowResize)
  }
}

</script>
<style scoped lang="scss">

.summary-components {
  display: flex;
  padding: 16px;
  background-color: $white;
  // box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  border-radius: 12px;

  .other-call-details {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 0.7s ease-in-out;
    &.extended {
      opacity: 1;
      max-height: 1000px;
    }
  }

  .toggle-button {
    display: flex;
    justify-content: center;
    svg {
      transition: 0.3s ease-in;
    }
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

p {
  white-space: normal
}

.table-wrapper {
  background-color: $white80;
  border-radius: 20px;
}

.summary-title {
  color: $slate40;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 11px;
}

.summary-value {
  color: $black80;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 14px;
}

.font-700 {
  font-weight: 700;
}

.call-notes {
  background-color: #F6F1EC;
  border-radius: 20px;
  &__header {
    border-bottom: 1px solid $black06;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 10px;
    padding: 8px 16px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
    }
    .save-text {
      display: flex;
      align-items: center;
      margin: 0;
      color: $slate40;
    }
  }
}

.single-counterpart {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.no-counter-part-selected-text {
  font-size: 16px;
  color: #7F8197;
}

.counterpart-edit-btn {
  padding: 0;
  margin-left: 6px;
  svg {
    width: 12px;
    height: 12px;
  }
}

</style>
